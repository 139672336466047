import TomSelect from "tom-select";
import MicroModal from "micromodal";

window.loadFragranceInfo = function(fragranceSlug){
  MicroModal.show("the-modal");
  document.querySelector("#the-modal .modal-container").innerHTML =
    '<div class="loader">Loading...</div>';

  fetch(`/fragrance-info/${fragranceSlug}`)
    .then((response) => response.text())
    .then((data) => {
      document.querySelector("#the-modal .modal-container").innerHTML = data;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

document.addEventListener("DOMContentLoaded", function () {
  MicroModal.init();

  document.querySelectorAll(".select2").forEach(function (el){
    let settings = {
      create: el.classList.contains("select2-tags"),
      createOnBlur: el.classList.contains("select2-tags"),
      persist: false
    }

    new TomSelect(el, settings)
  })
})
